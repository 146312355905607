import Navbar from "./Components/Navbar/Navbar";
import Videos from "./Components/Videos/Videos";
import VideoBlog from "./Components/Videos/VideoBlog/VideoBlog";
import Comment from "./Components/Comment/Comment";
import Events from "./Components/Events/Events";
import Home from "./Components/Home/Home";
import Essentials from './Components/Essentials/Essentials'
import {Routes, Route } from 'react-router-dom';
// import Essentials from "./Components/Essentials/Essentials";
import EssentialsDetail from "./Components/Essentials/EssentialsDetail";
import EventDetail from "./Components/Events/EventDetail/EventDetail";
import React from 'react';

function App() {
  return (
    <>

      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/videoBlog" element={<VideoBlog />} />
        <Route path="/events" element={<Events />} />
        <Route path="/comment" element={<Comment />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/essentials" element={<Essentials />} />
        {/* <Route path="/essentials_details" element={<EssentialsDetail />} /> */}
        <Route path="/essentials_details" element={<EventDetail />} />
      </Routes>
    </>
  );
}

export default App;

