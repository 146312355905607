import s from './Banner.module.scss'
import React from 'react';

export default function Banner(props){
    return(
        <div id={s.bannerContainerWrapper}>
            <div id={s.bannerContainer}>
                <img src={props.image} alt="" />
                {/* <div id={s.bannerImg}>
                </div> */}
                <div id={s.bannerTitle}>
                    {props.title}
                </div>
            </div>
        </div>
    )
}