import { useEffect, useState } from 'react';
import s from './EventDetail.module.scss';
import Button from '../../MiniComponents/Button/Button';
import React from 'react';

export default function EventDetail(props){
    const [image, setImage] = useState(props.image || 'Homepage/WhatDoWeOffer/events.jpg');
    const [videoSrc, setVideo] = useState(props.video || 'bannerVideo.mp4')
    const [eventTitle, setEventTitle] = useState(props.eventTitle || "Event Title");
    const [RCBgColor, setRCBgColor] = useState("rgba(255,255,255,0)");
    const [registerIcon, setRegisterIcon] = useState('flex');

    function scrollBottom(e){
        window.scrollTo({
            top: 450,
            left: 0,
            behavior: "smooth",
        });
        setRCBgColor("rgba(255,255,255,1)");
        setRegisterIcon('none');
    }

    useEffect(() => {

        window.addEventListener('scroll', updateButton)
        return () => {
            window.removeEventListener('scroll', updateButton);
        };
    })

    function updateButton(){
        if (window.scrollY >= 3 && window.scrollY <= 10){
            console.log("Scheduling....");
            setRCBgColor("rgba(255,255,255,1)");
            setRegisterIcon('none');
        } else if (window.scrollY < 3){
            console.log(window.scrollY);
            setRegisterIcon('flex');
            setRCBgColor("rgba(255,255,255,0)");
        }
    }

    return(
        <div id={s.Wrapper}>
            <div id={s.container}>
                <div id={s.banner} style={{backgroundImage: `url(${image})`}}>
                    <div id={s.bannerText}>
                        <div id={s.eventTitle}>
                            <h1>{eventTitle}</h1>
                        </div>
                        <div id={s.eventLocationNDate}>
                            <div id={s.location}>
                                <i class="fa-solid fa-location-dot"></i>
                                <h3>Location</h3>
                            </div>
                            <div id={s.date}>
                                <i class="fa-solid fa-calendar-days"></i>
                                <h3>28th Aug, 2023</h3>
                            </div>
                        </div>
                        <div id={s.eventSubttitle}>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
                        </div>
                    </div>
                </div>

                <div id={s.contentContainer}>
                    <div id={s.leftSection}>
                        <div id={s.leftSectionWrapper}>
                            <div id={s.eventDetailText}>
                                <div id={s.eventHeading}>
                                    <h1>
                                        About Us
                                    </h1>
                                </div>
                                <div id={s.ASCtext}>
                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero rerum cumque accusamus harum soluta porro, quis natus magni totam! Corporis, dolor numquam porro dolorem tenetur harum nesciunt qui eligendi accusantium animi itaque excepturi, deleniti molestiae ea veniam. Eum commodi architecto consectetur, quo quibusdam ratione aut et accusantium ad soluta fugiat labore! At quis necessitatibus molestiae doloremque debitis? Quam ad rem laborum molestiae aliquam illo at quaerat temporibus suscipit. Ut voluptates earum hic mollitia quod velit reiciendis, ipsam aut perferendis harum. Reiciendis ipsum, minima placeat facere praesentium expedita. Fuga animi omnis adipisci non quasi, magni soluta voluptates sapiente eaque, quaerat fugiat.</p>
                                </div>
                                <div id={s.videoSection}>
                                    <video width="100%" height="100%" autoPlay muted loop>
                                        <source src={videoSrc}/>
                                    </video>
                                </div>

                                <div id={s.extraSection}>
                                    <div id={s.ESTitle}>
                                        <h1>Some Title</h1>
                                    </div>
                                    <div id={s.ESCards}>
                                        <ESCard />
                                        <ESCard />
                                        <ESCard />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div id={s.rightSection}>
                        <div id={s.rightSectionWrapper}>
                            <div id={s.registerContainer} style={{backgroundColor: RCBgColor}}>
                                <div id={s.Rtitle} onClick={scrollBottom}>
                                    <i style={{display: registerIcon}} class="fa-solid fa-circle-down">
                                        <span id={s.pulse}></span>
                                    </i>
                                    <h1>Register Now</h1>
                                </div>
                                <div id={s.inputFields}>
                                    <div className={s.inpfield}>
                                        <input type="text" placeholder='Email'/>
                                    </div>
                                    <div className={s.inpfield}>
                                        <input type="text" placeholder='First Name'/>
                                    </div>
                                    <div className={s.inpfield}>
                                        <input type="text" placeholder='Last Name'/>
                                    </div>
                                    <div className={s.inpfield}>
                                        <input type="text" placeholder='College Name'/>
                                    </div>
                                    <div id={s.submitButton}>
                                        <Button text="Register" bgColor="#3070e6" afterColor="green" textColor="white"/>
                                    </div>
                                </div>
                            </div>
                            
                            <div id={s.speakersSection}>

                                <div id={s.speakersTitle}>
                                    <h1>Speakers</h1>
                                </div>

                                <div className={s.speaker}>
                                    <div className={s.speakerIconContainer}>
                                        <img src="avatar.jpg" alt="" />
                                    </div>
                                    <div className={s.speakerDetailsContainer}>
                                        <h1> Tanmay Chavan </h1>
                                        <p>Full Stack Developer</p>
                                    </div>
                                </div>
                                
                                <div className={s.speaker}>
                                    <div className={s.speakerIconContainer}>
                                        <img src="avatar.jpg" alt="" />
                                    </div>
                                    <div className={s.speakerDetailsContainer}>
                                        <h1> Tanmay Chavan </h1>
                                        <p>Full Stack Developer</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ESCard(props){
    return(
        <div id={s.ESCard}>
            <div id={s.icon}>
                <i class="fa-solid fa-compact-disc"></i>
            </div>
            <div id={s.cardText}>
                <p>Lorem ipsum dolor sit.</p>
            </div>
        </div>       
    )
}