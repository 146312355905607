import s from "./Events.module.scss";
import { eventData } from "./EventsData";
import Banner from "../Banner/Banner";
import React from 'react';

function Events() {
  
  return (
    <>
      <Banner image={window.location.origin + "/events/event2.jpg"} title="Events & Webinars" />
      
      <div className={s.eventsContainer}>
        <div className={s.eventsTitle}>
          <h2>Our Upcoming Events</h2>
          <p>
            Jeffrey crikey victoria sponge mush spiffing super arse over tit
            matie boy smashing. The little rotter off his nut codswallop.!
          </p>
        </div>
        {eventData.map((event) => (
          <div key={event.id}>
            <EventCard
              imgUrl={window.location.origin + "/" + event.imgUrl}
              date={event.date}
              title={event.title}
              location={event.location}
              description={event.description}
            />
          </div>
        ))}
        ;
      </div>
    </>
  );
}

function EventCard({ imgUrl, date, title, time, location, description }) {
  return (
    <div className={s.eventCard}>
      <div id={s.leftSection}>
          <img src={imgUrl} className={s.imgFluid} alt="event_image" />
      </div>

      <div className={s.eventContent}>
        <h5>{date}</h5>
        <h2>{title}</h2>

        <div>
          <p>
            <i className="icon_clock_alt">{time}</i>
          </p>

          <p>
            <i className="icon_clock_alt">{location}</i>
          </p>
        </div>

        <p>{description}</p>
      </div>
    </div>
  );
}

export default Events;
