import s from './EventCards.module.scss'
import { eventData } from "./EventsData";
import React from 'react';

export default function EventCards() {
    return (
        <div id={s.eventCardContainer}>
            <div id={s.heading}>
                <h1>
                    Upcoming Events
                    {/* <span id={s.underline}></span> */}
                </h1>

            </div>
            <div id={s.videoGridWrapper}>
                {eventData.map((event) => (
                    <div key={event.id}>
                        <EventCard
                        imgUrl={window.location.origin + "/" + event.imgUrl}
                        date={event.date}
                        title={event.title}
                        location={event.location}
                        description={event.description}
                        />
                    </div>
                ))}

                {/* <div id={s.videosGrid}>
                    <Card />                                
                </div> */}
            </div>
        </div>
    )
}

function EventCard({ imgUrl, date, title, time, location, description }) {
    return (
      <div className={s.eventCard}>
        <div id={s.leftSection}>
            <img src={imgUrl} className={s.imgFluid} alt="event_image" />
        </div>
  
        <div className={s.eventContent}>
          <h5>{date}</h5>
          <h2>{title}</h2>
  
          <div>
            <p>
              <i className="icon_clock_alt">{time}</i>
            </p>
  
            <p>
              <i className="icon_clock_alt">{location}</i>
            </p>
          </div>
  
          <p>{description}</p>
        </div>
      </div>
    );
  }