import s from './Videos.module.scss'
import Card from '../Card/Card'
import React from 'react';

export default function Videos(){
    function openVideo(){
        console.log("here")
    }

    return(
        <>
            <div id={s.videosTitle}>
                <h3>Videos to Explore</h3>
            </div>

            <div id={s.videoGridWrapper}>
                <div id={s.videosGrid}>
                    <Card img="events/event1.jpg" handleClick={openVideo} />           
                    <Card img="events/event4.jpg" />
                    <Card img="events/event3.jpg" />
                    <Card img="events/event1.jpg" />           
                    <Card img="events/event4.jpg" />
                    <Card img="events/event3.jpg" />
                    <Card img="events/event1.jpg" />           
                    <Card img="events/event4.jpg" />
                    <Card img="events/event3.jpg" />
                </div>
            </div>
        </>
    )
}