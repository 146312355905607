import { useEffect, useRef, useState } from 'react'
import s from './Button.module.scss'
import React from 'react';

/**
 * Requires a parent container to give height to it as it consumes 100% height of parent
 * @param {*} text: to display it on the button 
 * @param {*} bgColor: Color to be used when not hovered over the button
 * @param {*} afterColor: Color to be used when hovered over the button
 * @param {*} textColor: Text color after hovered over the button
 * 
 * @returns 
 */
export default function Button(props){
    const [buttontext, setButtonText]  = useState(props.text || "Read More")
    const [bgColor, setBgColor] = useState(props.bgColor || "black")
    const [afterColor, setAfterColor] = useState(props.afterColor || "#f7f7f7")
    const [textColor, setTextColor] = useState(props.textColor || "black")

    const normalStyles = {
        cursor: "pointer",
        height: "100%",
        border: "none",
        color: "white",
        fontSize: "1rem",
        fontWeight: "600",
        // width: "140px",
        padding: "8px 15px",
        backgroundColor: bgColor,
        transition: "1s"
    }
    const [buttonStyles, setButtonStyles] = useState(normalStyles);
    
    const hoverStyles ={
        boxShadow: `inset 500px 0px 0px ${afterColor}`,
        color: textColor,
    }
    const button = useRef()
    useEffect(() => {
        const ctaElement = document.querySelector(`${s.CTA}`);
        
        // Add a mouseover event listener to apply the hover effect
        button.current.addEventListener('mouseover', () => {
            setButtonStyles({...normalStyles, ...hoverStyles})
            button.current.classList.add('hovered');
        });

        // Add a mouseout event listener to remove the hover effect
        button.current.addEventListener('mouseout', () => {
            setButtonStyles(normalStyles)
            button.current.classList.remove('hovered');
        });
    })

    return(
        <button id={s.CTA} ref={button} style={buttonStyles} onClick={props.handleClick}> 
            {buttontext}
            <i class="fa-solid fa-angles-right"></i>    
        </button>
    )
}