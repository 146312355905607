import s from './VideoBlog.module.scss'
import Banner from '../../Banner/Banner'
import React from 'react';

export default function VideoBlog(){
    return(
        <>
            <Banner image="events/event2.jpg" title="Page Title"/>
            <div id={s.videoBlogContainer}>
                <div id="videoContainer" >
                    <video controls>
                        <source src="bannerVideo.mp4" type="video/mp4" />
                    </video>
                </div>

                <div id={s.metaData}>
                    <div className={s.metaItem}>
                        <i className="fa-solid fa-user-tie"></i>
                        <span>Author Name</span>
                    </div>
                    <div className={s.metaItem}>
                        <i className="fa-solid fa-calendar-days"></i>
                        <span>12/12/2022</span>
                    </div>
                    <div className={s.metaItem}>
                        <i className="fa-solid fa-heart"></i>
                        <span>121 Likes</span>
                    </div>
                </div>

                <div id={s.videoBlogTitle}>Lorem ipsum</div>
                <div id={s.videoBlogDesc}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium, deleniti blanditiis dolor quas animi debitis quasi, eaque autem corporis quisquam, accusantium velit aperiam odio aliquam vel quod provident! Accusantium nemo quam, cupiditate repellat laudantium veniam, aut quaerat mollitia velit esse et illum distinctio placeat id quisquam eaque ex nesciunt quo quidem culpa accusamus vel impedit voluptates fugiat? Sint sequi delectus cumque, dignissimos mollitia reprehenderit dolore optio minus id culpa tempore perspiciatis dicta laborum nobis obcaecati maxime quae error, soluta maiores? Molestias, deserunt deleniti consequatur modi qui quam iste
                </div>
                <div id={s.videoBlogDesc}>
                Tempore ipsa cumque error nobis reiciendis iusto numquam rem eos, minima totam illo rerum sequi minus accusamus tempora quas! Molestiae mollitia et rerum magnam ab! Iure sunt laudantium quis atque. Accusamus magnam atque veniam nam, aut ut aliquam voluptas rem consequatur nisi similique eos quod enim, temporibus repellendus nostrum eius. Quibusdam ipsa provident ipsam nemo quaerat error necessitatibus debitis aut voluptate eveniet maxime beatae ab, deleniti vel rem? Ipsa delectus similique nemo omnis consequatur exercitationem. Quos, architecto quam soluta fuga fugit esse aut facere vero est fugiat unde quisquam officiis aspernatur aliquid delectus praesentium eius labore voluptatibus! Facere fuga aperiam nemo, corrupti recusandae eligendi distinctio ab deleniti! Magni voluptas possimus quos rem?
                </div>
            </div>
        </>
    )
}