import s from './Card.module.scss'
import Button from '../MiniComponents/Button/Button'
import React from 'react';

export default function Card(props){
    return(
        <div id={s.cardContainer}>
            <div id={s.imageContainer}>
                <img src={props.img} alt="props.img" />
            </div>
            <div id={s.eventContent}>
                <div id={s.title}>
                    <h3> Lorem ipsum dolor sit amet consectetur. </h3>
                </div>
                <div id={s.metaDataContainer}>
                    <div className={s.metaData}>
                        <i className="fa-solid fa-user-tie"></i>
                        <p>Author</p>
                    </div>
                    <div className={s.metaData}>
                        <i className="fa-solid fa-calendar-days"></i>
                        <p> 07/23</p>
                    </div>
                    <div className={s.metaData}>
                        <i className="fa-solid fa-heart"></i>
                        <p>35 Likes </p>
                    </div>
                </div>
                <div id={s.jist}>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur, sint. Iure quidem rerum at itaque molestiae dolore. Facere, sapiente dolores.</p>
                </div>
                <div id={s.buttonContainer}>
                    {/* <button onClick={props.handleClick}> Read More </button> */}
                    <Button text="Read More" />
                </div>
            </div>
        </div>
    )
}