export const eventData = [
    {
      id: 1,
      imgUrl: "events/event3.jpg",
      date: "14 JANUARY, 2023",
      title: "Why you Need Advance Developmemt Process Course?",
      time: "8.30 AM - 5.30 PM",
      location: "9 Road, Mirpur Dohs, New York, USA",
      description:
        "He lost his bottle bamboozled golly gosh some dodgy chav only a quid my lady chip shop argy-bargy, the bee's knees morish loo get stuffed mate pardon me zonked knees.!",
    },
    {
      id: 2,
      imgUrl: "events/event3.jpg",
      date: "14 JANUARY, 2023",
      title: "Graphic Illustration: Design with Color and Shape",
      time: "8.30 AM - 5.30 PM",
      location: "9 Road, Mirpur Dohs, New York, USA",
      description:
        "He lost his bottle bamboozled golly gosh some dodgy chav only a quid my lady chip shop argy-bargy, the bee's knees morish loo get stuffed mate pardon me zonked knees.!",
    },
    {
      id: 3,
      imgUrl: "events/event3.jpg",
      date: "14 JANUARY, 2023",
      title: "Magazine Design Start to Finish: The Inside Pages",
      time: "8.30 AM - 5.30 PM",
      location: "9 Road, Mirpur Dohs, New York, USA",
      description:
        "He lost his bottle bamboozled golly gosh some dodgy chav only a quid my lady chip shop argy-bargy, the bee's knees morish loo get stuffed mate pardon me zonked knees.!",
    },
  ];