import { useState } from 'react'
import s from './WhatDoWeOffer.module.scss'
import React from 'react';

export default function WhatDoWeOffer(props){
    return(
        <div id={s.Wrapper}>
            <div id={s.container}>
                <div id={s.heading}>

                    <h1>
                        <span id={s.circle1}></span>
                        What Do We Offer ?
                    </h1>
                </div>
                <div id={s.innerContainer}>
                    <div className={s.row}>
                        <section id={s.bigsec}>
                            <Block color="white" image="Homepage/WhatDoWeOffer/courses.png" heading="Courses" text="Empower your skills with our insightful courses."/>
                        </section>
                        <section className={s.smlsec}>
                            <Block color="white" image="Homepage/WhatDoWeOffer/webinars.png" heading="Events & Webinars" text="Expand your knowledge through engaging webinars."/>
                        </section>
                    </div>
                    <div className={s.row}>
                        <section className={s.smlsec}>
                            <Block color="white" image="Homepage/WhatDoWeOffer/retreats.png"  heading="Retreats" text="Experience enriching events that inspire growth."/>
                        </section>
                        <section className={s.smlsec}>
                            <Block color="white" image="Homepage/WhatDoWeOffer/blogs.png"  heading="Blogs" text="Explore our student-focused blogs for valuable insights."/>
                        </section>
                        <section className={s.smlsec}>
                            <Block color="white" image="Homepage/WhatDoWeOffer/lifeEssentials.png"  heading="Life Essentials" text="Receive essential life messages tailored for students"/>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Block({color, image, heading, text}){
    const [bgColor, setBgColor] = useState(color || 'red');
    const [bgImage, setImage ] = useState(image)
    return(
        <div id={s.blockContainer} style={{backgroundColor: color, backgroundImage: `url(${bgImage})`}}>
            <div id={s.blockTextContainer}>
                <div id={s.textHeading}>
                    <h1>{heading}</h1>
                </div>
                <div id={s.text}>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    )
}