import s from "./Testimonial.module.scss"
import CustomCarousel from "../../Carousel/Carousel";
import { testimonials } from "./testimonials";
import React from 'react';

export default function TestimonialSection(props) {
    return (
        <div id={s.testimonialSectionWrapper}>
            <div id={s.testimonialSectionContainer}>
                <div id={s.TSLeftSection}>
                    <div id={s.TSanimationContainer}>
                        <div id={s.circleAnimation1} />
                        <div id={s.circleAnimation2} />
                    </div>
                    <div id={s.TSLSText}>
                        <h1>People Say About Us.</h1>
                        <img src="quote.png" alt="Quote" />
                    </div>
                </div>
                <duv id={s.TSRightSection}>
                    <div id={s.TcarouselContainer}>
                        <div id={s.TcarouselWrapper}>
                            <CustomCarousel containers={[
                                testimonials.map((testimonial, index) => {
                                    console.log(testimonial.id)
                                    return (
                                        <div id={s.testimonialContainer} key={testimonial.id}>
                                            <div id={s.TCcontent}>
                                                <p>{testimonial.text}</p>
                                            </div>
                                            <div id={s.TCInfo}>
                                                <div id={s.TCImageSection}>
                                                    <img src={testimonial.avatar} alt="" />
                                                </div>
                                                <div id={s.TCNameSection}>
                                                    <p className={s.testyName}> {testimonial.name} </p>
                                                    <p className={s.testyDesig}>{testimonial.desig}</p>
                                                    <p className={s.testyDesig}>{testimonial.desig2 || ""}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ]}
                                interval={5000}
                            />
                        </div>
                    </div>
                </duv>
            </div>
        </div>
    )
}