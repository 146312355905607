import React from "react";
import Comment from "../Comment/Comment";
import EssentialsCard from "./EssentialsCard"
import {essentialsData as data} from './EssentialsData'
import s from "./EssentialsDetail.module.scss";

function EssentialsDetail(props) {
  return (

      <div className={s.essentialsDetailsContainer}>
        <section className={s.upperSection}>

          <div className={s.detailsLeft}>
            <LeftDetails imgSrc="./essentials/essentials1.jpg" 
            title="There never was any heart truly great and generous, that was not also tender and compassionate." 
            author="Author" />
          </div>

          <div className={s.detailsRight}>
            <RightDetails />
          </div>

        </section>

        <section className={s.lowerSection}>
          <div className={s.essentialContent}>
            {data.map(e => {
              return <EssentialsCard imgSrc={e.imgSrc} redirectTo={e.redirectTo} title={e.title} />
            })}
          </div>
        </section>
      </div>

    
  );
}

function LeftDetails(props) {

    return (
        <div>
          <img src={props.imgSrc} alt="Card_Image" />
          <h2>“{props.title}”</h2>
          <p>{props.author}</p>
          <button>Button</button>
        </div>
    );
}

function RightDetails(props) {

    return (
      <div>
        <Comment />
        <div className={s.userComment}>
          <p>Your Comment</p>
          <textarea cols="30" rows="5" placeholder="Share your comment here"></textarea>
          <input type="text" placeholder="Your Name" />
          <input type="text" placeholder="Location" />
          <button>CREATE COMMENT</button>
        </div>
      </div>
    );
}

export default EssentialsDetail;
