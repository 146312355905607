import { useState } from 'react'
import s from './Comment.module.scss'
import React from 'react';

export default function Comment(props){

    const [ data, updateData ] = useState([
        {
            author: "Tanmay Chavan",
            date: "20/2/23",
            comment: "hey there!",
            img: "Some url",
            replies: [
                {
                    author: "Lord Tejas",
                    date: "20/2/23",
                    comment: "hey there!",
                    img: "Some url",
                    replies: [
                        {
                            author: "Lord Shubham",
                            date: "20/2/23",
                            comment: "hey there!",
                            img: "Some url",
                            replies: [
                                {
                                    author: "Anjali Salvi",
                                    date: "20/2/23",
                                    comment: "hey there!",
                                    img: "Some url",
                                    replies: []
                                },
                                {
                                    author: "Tanmay Chavan",
                                    date: "20/2/23",
                                    comment: "hey there!",
                                    img: "Some url",
                                    replies: []
                                }
                            ]
                        }
                    ]
                }, 
                {
                    author: "Lord Viraj",
                    date: "20/2/23",
                    comment: "hey there!",
                    img: "Some url",
                    replies: []
                }
            ]
        }, 
        {
            author: "Sir Menon",
            date: "20/2/23",
            comment: "hey there!",
            img: "Some url",
            replies: [
                {
                    author: "Lord Viraj",
                    date: "20/2/23",
                    comment: "hey there!",
                    img: "Some url",
                    replies: [
                        {
                            author: "Tanmay Chavan",
                            date: "20/2/23",
                            comment: "hey there!",
                            img: "Some url",
                            replies: []
                        }
                    ]
                }
            ]
        }
    ]);

    const [showReplySection, setShowReplySection] = useState(true);

    const toggleRepliesContainer = (index) => {
        console.log(index);
        setShowReplySection(prevVal => !prevVal);
    }

    return(
        <PopulateContainerWithComments 
            data={data}
            index={0}
            toggleRepliesContainer={toggleRepliesContainer}
            showReplySection={showReplySection}
        />
    )
}




function PopulateContainerWithComments(props){
    return(
        <div id={s.commentContainer}>
            {
                props.data.map(post => {
                    // let display = props.index == 0 ? "flex" : "none";
                    let display = props.showReplySection ? "flex": "none";
                    let border = props.index == 0 ? "none" : "1px solid rgb(206, 206, 206)"
                    
                    return(
                        <CommentSection 
                            author={post.author}
                            index={props.index}
                            replies={post.replies}
                            // toggleRepliesContainer={toggleRepliesContainer}
                            showReplySection={props.showReplySection}
                        />
                        // <div data-index={props.index + '/' + post.author} id={s.comment} style={{display: display}} >
                        // {/* <div data-index={props.index + '/' + post.author} id={s.comment}  > */}
                        //     <div id={s.commentLeftSection}>
                        //     </div>
                        //     <div id={s.commentRightSection} style={{borderLeft: border}}>
                        //         <div id={s.authorNameContainer}>
                        //             <div id={s.avatarSection}></div>
                        //             <h1>{post.author}</h1>
                        //         </div>
                        //         <div id={s.commentContent}>
                        //             <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea laudantium quasi modi nostrum reprehenderit deleniti illo error voluptates voluptatibus, dolores totam voluptas sapiente quis tempore!</p>
                        //             <div id={s.likeShareReply}>
                        //                 <i class="fa-regular fa-thumbs-up"></i>
                        //                 <i class="fa-regular fa-thumbs-down"></i>
                        //                 <div id={s.replyButton}>
                        //                     <i class="fa-solid fa-reply"></i>
                        //                     Reply
                        //                 </div>
                        //                 <p onClick={() => toggleRepliesContainer(props.index)}>
                        //                     Replies 
                        //                     {
                        //                         showReplySection ? 
                        //                             <i class="fa-solid fa-angle-up"></i>
                        //                         :
                        //                             <i class="fa-solid fa-angle-down"></i>
                        //                     }
                                            
                        //                 </p>
                        //             </div>
                        //         </div>
                        //         <DrawPost data={post.replies} index={props.index + 1} toggleRepliesContainer={toggleRepliesContainer} showReplySection={showReplySection} />
                        //     </div>
                        // </div>
                    )
                })
            }
        </div>
    )
}

function  CommentSection(props){
    const [showReplySection, setShowReplySection] = useState(false);

    const toggleRepliesContainer = (index) => {
        console.log(index);
        setShowReplySection(prevVal => !prevVal);
    }
    let display = props.showReplySection ? "flex": "none";
    let border = props.index == 0 ? "none" : "1px solid rgb(206, 206, 206)"

    return(
        
        <div data-index={props.index + '/' + props.author} id={s.comment} style={{display: display}} >
        {/* <div data-index={props.index + '/' + post.author} id={s.comment}  > */}
            <div id={s.commentLeftSection}>
            </div>
            <div id={s.commentRightSection} style={{borderLeft: border}}>
                <div id={s.authorNameContainer}>
                    <div id={s.avatarSection}>
                        <img src="avatar.png" alt="Avatar" />
                    </div>
                    <h1>{props.author}</h1>
                </div>
                <div id={s.commentContent}>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea laudantium quasi modi nostrum reprehenderit deleniti illo error voluptates voluptatibus, dolores totam voluptas sapiente quis tempore!</p>
                    <div id={s.likeShareReply}>
                        <i class="fa-regular fa-thumbs-up"></i>
                        <i class="fa-regular fa-thumbs-down"></i>
                        <div id={s.replyButton}>
                            <i class="fa-solid fa-reply"></i>
                            Reply
                        </div>
                        {
                            props.replies.length > 0 ?
                                <p onClick={() => toggleRepliesContainer(props.index)}>
                                    Replies 
                                    {
                                        showReplySection ? 
                                            <i class="fa-solid fa-angle-up"></i>
                                        :
                                            <i class="fa-solid fa-angle-down"></i>
                                    }
                                    
                                </p>
                            :
                                null
                        }
                    </div>
                </div>
                <PopulateContainerWithComments
                    data={props.replies}
                    index={props.index + 1}
                    toggleRepliesContainer={toggleRepliesContainer}
                    showReplySection={showReplySection}
                />
            </div>
        </div>
    )
}