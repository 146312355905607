export const testimonials = [
    {
        id: 1,
        type: "text",
        avatar: "avatar.jpg",
        name: "Mr. Vijay Shejval Patil",
        text: "Shrestha Club has helped me to become a better version of myself by engaging my passion in the right direction.",
        // desig: "Deputy General Manager at Adani Electricity"
        desig: "B.Tech from VJTI Mumbai, MBA from IIFT, Deputy Analytics Manager at HT Digital Media"
    },
    {
        id: 2,
        type: "text",
        avatar: "avatar.jpg",
        name: "Mr. Gokul Ramesh",
        text: "Professional career not only requires technical knowledge but spiritual values as well. And all thanks to Shreshta club for building a strong spiritual foundation in me.",
        desig: "2011 Gate AIR 1"
    },
    {
        id: 3,
        type: "text",
        avatar: "avatar.jpg",
        name: "Mr. Barun Ghosh",
        text: "Their group activities trained me to be deeply insightful and relevant in today's multifaceted, fast moving and complex world.",
        desig: "B.Tech from VJTI Mumbai, MBA from IIFT, Deputy Analytics Manager at HT Digital Media"
    },
    {
        id: 4,
        type: "text",
        avatar: "avatar.jpg",
        name: "Mr. Saurav Anand",
        text: 'I learnt unique abilities to blend the ancient wisdom with modern day situations.',
        desig:" B.Tech from IIT Bombay",
        desig2: "Co-Founder - Soulful Mealz"
    }
]