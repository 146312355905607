import s from "./TeamMembers.module.scss"
import Button from "../../MiniComponents/Button/Button"
import React from 'react';

export default function TeamMembers(props){
    return(
        <div id={s.teamMembersWrapper}>
            <div id={s.teamMemberContainer}>
                <div id={s.upperSection}>
                    <div id={s.design}>
                        <div className={s.bar} />
                        <div className={s.bar} />
                        <div className={s.bar} />
                        <div className={s.bar} />
                        <div className={s.bar} />
                        <div className={s.bar} />
                        <div className={s.bar} />
                        <div className={s.bar} />
                    </div>
                    <div id={s.heading}>
                        <h1>SENIOR PROFESSIONALS</h1>
                    </div>
                    <div id={s.subTitle}>
                        <p>We have a strong team of Senior Professionals who teach & share their <br /> knowledge & experiences </p>
                    </div> 
                </div>
                <div id={s.lowerSection}>
                    <div id={s.lowerSectionWrapper}>
                        <div className={s.card}>
                            <div className={s.image}>
                                <img src="Homepage/teamMembers/Dr Ranjeet.png" alt="" />
                            </div>
                            <div className={s.name}>
                                <p>Dr. Ranjeet Gupta</p>
                                <p>University, Scotland
                                    Research Associate at 
                                    Heriot-Watt University, UK
                                </p>
                            </div>
                        </div>
                        <div className={s.card}>
                            <div className={s.image}>
                                <img src="Homepage/teamMembers/Dr Shekhar.png" alt="" />
                            </div>
                            <div className={s.name}>
                                <p>Dr. Shekhar Shukla</p>
                                <p>Professor at IIM Indore
                                    MBA & Ph.D, IIM Lucknow TATA Scholar
                                </p>
                            </div>
                        </div>
                        <div className={s.card}>
                            <div className={s.image}>
                                <img src="Homepage/teamMembers/Siddharth P.png" alt="" />
                            </div>
                            <div className={s.name}>
                                <p>Mr. Sidharth Kumar</p>
                                <p>Founder & CEO, BTA International India Pvt Ltd
                                    B.Tech from BITS Pilani
                                </p>
                            </div>
                        </div>
                        <div className={s.card}>
                            <div className={s.image}>
                                <img src="Homepage/teamMembers/Vicky Dhanwanu.jpg" alt="" />
                            </div>
                            <div className={s.name}>
                                <p>Mr Vicky Dhanwani</p>
                                <p>Ex-Googler, Founder of 6Miles Consulting</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}