import React, { useEffect, useRef } from 'react';
import "../Navbar/Navbar.scss";

export default function Navbar(props){
    const [bgColor, updateBgColor] = React.useState([0, "rgba(0, 0, 0, 1)"]);

    // useEffect(() => {
    //     function updateBackgroundColor() {
    //         const newBgColor = window.scrollY >= 200 ? [1, "rgba(0, 0, 0, 0.8)"] : (window.location.pathname == "/" ? [0, "rgba(0, 0, 0, 0)"] :[1, "rgba(0, 0, 0, 0.8)"] );
    //         if (window.location.pathname !== "/") {
    //             newBgColor[0] = 1;
    //         }
    //         updateBgColor(newBgColor);
    //     }

    //     updateBackgroundColor(); // Initial calculation

    //     window.addEventListener('scroll', updateBackgroundColor);

    //     return () => {
    //         window.removeEventListener('scroll', updateBackgroundColor);
    //     };
    // }, []);

    function toggleMenu(){
        document.querySelector('.menuItemContainer').classList.toggle('active');
    }

    return(
        <div id="navbarContainer" style={{backgroundColor: bgColor[1]}}>
            <div id= "logoContainer">
                <a href="/">
                    <img src={window.location.origin+ "/" + "logo.png"} alt="Logo" />
                </a>
            </div>
            <div id="menuContainer">
                <i className="fa-solid fa-bars" onClick={toggleMenu}></i>

                <div className="menuItemContainer">
                    <a href="/" className="menu">
                        <div> <p>Home</p> </div> 
                    </a>
                    <a href="#" className="menu">
                        <div> <p>About</p> </div> 
                    </a>
                    <a href="/events" className="menu">
                        <div> <p>Events & Webinars</p> </div> 
                    </a>
                    <a href="#" className="menu">
                        <div> <p>Resources</p> </div> 
                    </a>
                    <a href="#" className="menu">
                        <div> <p>Courses</p> </div> 
                    </a>
                    <a href="#" className="menu">
                        <div> <p>Contact Us</p> </div> 
                    </a>
                </div>
            </div>
        </div>
    )
}
