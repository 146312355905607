import s from "./GetEmpowered.module.scss"
import React from 'react';

export default function GetEmpowered(props) {
    return (
        <div id={s.GEWrapper}>
            <img src="extAnimations/GECurve.png" alt="Curve" id={s.GECurve} />
            <img src="extAnimations/GEsquare.png" alt="Square" id={s.GESquare} />
            <img src="extAnimations/GETriangle.png" alt="Triangle" id={s.GETriangle} />
            <div id={s.getEmpoweredContainer}>
                <div id={s.GECHeadingContainer}>
                    <div id={s.GECTitle}>
                        <h1>Core Values</h1>
                    </div>
                    <div id={s.GECsubTitle}>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde veritatis excepturi, consequuntur nesciunt voluptate quas.</p> */}
                    </div>
                </div>
                <div id={s.GECcardsContainer}>
                    <GECCard img="Community.png" heading="Community" text='We harness the power of collaboration between people' />
                    <GECCard img="Excellence.png" heading="Excellence" text="We will always strive to do our best work in service of our community" />
                    <GECCard img="Teach-Learn.png" heading="Teaching & Learning" text="We are lifelong learners and share our knowledge openly" />
                    <GECCard img="Integrity.png" heading="Integrity" text="We will be honest and aligned with our values - always." />
                    <GECCard img="Passion.png" heading="Passion" text="We approach our work with focus and intentionality of commitment" />
                    <GECCard img="Diversity.png" heading="Diversity & Inclusion" text=" We embrace and engage with the diverse identities, ideas, and interests that each person brings" />
                    <GECCard img="Spirituality.png" heading="Spirituality" text="We promote studying ancient literature to grasp life's purpose, achieve goals, and live by principles" />
                </div>
            </div>
        </div>
    )
}


function GECCard(props) {
    return (
        <div className={s.GECCardContainer}>
            <div className={s.GECCiconContainer}>
                <img src={"Homepage/CoreValues/" + props.img} alt="" />
            </div>
            <div className={s.GECCTitleCotnainer}>
                <h2>{props.heading}</h2>
            </div>
            <div className={s.GECCSubtitleContainer}>
                <p>{props.text}</p>
            </div>
        </div>
    )
}