export  const essentialsData = [
    {
        id: 1,
        imgSrc: "essentials/essentials1.jpg",
        redirectTo: "/essentials_details",
        title: "Title",
    },{
        id: 2,
        imgSrc: "essentials/essentials1.jpg",
        redirectTo: "/essentials_details",
        title: "Title",
    },{
        id: 3,
        imgSrc: "essentials/essentials1.jpg",
        redirectTo: "/essentials_details",
        title: "Title",
    },{
        id: 4,
        imgSrc: "essentials/essentials1.jpg",
        redirectTo: "/essentials_details",
        title: "Title",
    },{
        id: 5,
        imgSrc: "essentials/essentials1.jpg",
        redirectTo: "/essentials_details",
        title: "Title",
    },{
        id: 6,
        imgSrc: "essentials/essentials1.jpg",
        redirectTo: "/essentials_details",
        title: "Title",
    },{
        id: 7,
        imgSrc: "essentials/essentials1.jpg",
        redirectTo: "/essentials_details",
        title: "Title",
    },{
        id: 8,
        imgSrc: "essentials/essentials1.jpg",
        redirectTo: "/essentials_details",
        title: "Title",
    },{
        id: 9,
        imgSrc: "essentials/essentials1.jpg",
        redirectTo: "/essentials_details",
        title: "Title",
    },{
        id: 10,
        imgSrc: "essentials/essentials1.jpg",
        redirectTo: "/essentials_details",
        title: "Title",
    }
]