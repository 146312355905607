import s from "./Carousel.module.scss"
import { useState, useRef, useEffect } from "react";
import React from 'react';

export default function Carousel(props) {
    const [animationInterval, udpateAnimationInterval] = useState( props.interval || 4000); // [ms
    const [containerCount, updatecontainerCount] = useState(props.containers[0].length);
    const [imageWidth, updateImageWidth] = useState(100 / containerCount);
    const [direction, setDirection] = useState(true); // True represents the forward direction and vice versa
    const [position, updatePosition] = useState(0);
    const intervalRef = useRef(null);

    const animateSlider = () => {
        if (position === -100) {
            setDirection(true); // Set direction back to true to go forward
        } else if (position === -1 * ((containerCount - 2) * 100)) {
            setDirection(false); // Update the direction using setDirection
        }

        if (direction) {
            updatePosition((prevPosition) => prevPosition - 100);
        } else {
            updatePosition((prevPosition) => prevPosition + 100);
        }
    }

    useEffect(() => {
        if (intervalRef.current){
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(animateSlider, animationInterval);
        return () => clearInterval(intervalRef.current);
    }, [direction, position, containerCount]);

    return (
        <div id={s.slider} style={{ width: containerCount * 100 + "%", position: "relative", left: `${position}%` }}>
            {
                props.containers[0].map((photo, index) => {
                    return (
                        <div key={index} className={s.slide} style={{ width: `${imageWidth}%` }}>
                            {photo}
                        </div>
                    )
                })
            }
        </div>
    )
}