import style from "./Footer.module.scss"
import React from 'react';

export default function Footer(props){
    return(
        <div id={style.footerContainer}>
            <div id={style.footerWrapper}>
                <div id={style.footerTopSection}>
                    <div id={style.topLeftSection}>
                        <img src="logo.png" alt="" />
                        <p></p>
                    </div>
                    <div id={style.topRightSection}>
                        <div id={style.socialMediaContainer}>
                            <span id={style.followUs}style={{margin: "0px 20px"}}>Follow us</span>
                            <p>
                                <a href="https://instagram.com/shresthaclub">
                                    <i class="fa-brands fa-instagram"></i>
                                </a>
                                <a href="https://linkedin.com/company/shrestha-club">
                                    <i class="fa-brands fa-linkedin"></i>
                                </a>
                                <a href="https://facebook.com/shresthaclub11">
                                    <i class="fa-brands fa-facebook"></i>
                                </a>
                                <a href="https://youtube.com/@shresthaclub?sub_confirmation=1">
                                    <i class="fa-brands fa-youtube"></i>
                                </a>
                                <a href="https://wa.me/+917045046628">
                                    <i class="fa-brands fa-whatsapp"></i>
                                </a>
                                <a href="https://t.me/shresthaclub11">
                                    <i class="fa-brands fa-telegram"></i>
                                </a>
                                <a href="https://facebook.com/shresthaclub11">
                                    <i class="fa-brands fa-facebook"></i>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div id={style.footerBottomSection}>
                    
                </div>
            </div>
        </div>
    )
}