import React from 'react'
import {essentialsData as data} from './EssentialsData'
import EssentialsCard from "./EssentialsCard";
import s from './Essentials.module.scss'

function Essentials() {
  return (
    <div className={s.essentialContainer}>
      <div className={s.essentialTitle}>
        <h5 id={s.title}>INSPIRATIONAL QUOTES</h5>
        <p id={s.subTitle}>Find the perfect quote... and Pass It On®</p>
      </div>

      <div className={s.essentialContent}>
        {data.map(e => {
          return <EssentialsCard imgSrc={window.location.origin + '/' + e.imgSrc} redirectTo={e.redirectTo} title={e.title} />
        })}
      </div>

    </div>
  )
}



export default Essentials
