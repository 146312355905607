import React from 'react'
import s from "./EssentialsCard.module.scss"

function EssentialsCard(props) {
  return (
    <div className={s.essentialCard}>

            <div className={s.imageContainer}>
              <a href={props.redirectTo}>
                <img src={props.imgSrc} alt="Card_Image" />
              </a>
            </div>

            <div className={s.cardContent}>

              <div className={s.title}>
                <h3>{props.title}</h3>
              </div>

              <div className={s.buttonContainer}>
                <button>BUTTON</button>
              </div>

            </div>

        </div>
  )
}

export default EssentialsCard
