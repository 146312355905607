import { useEffect, useState, useRef, useCallback } from "react";
import s from "./Home.module.scss"
import Card from "../Card/Card";
import Footer from "../Footer/Footer";
import EventCards from "./UpcomingEvents/EventCards";
import WhatDoWeOffer from "./WhatDoWeOffer/WhatDoWeOffer";
import AboutShresthaClub from "./AboutShresthaClub/AboutShresthaClub";
import GetEmpowered from "./GetEmpowered/GetEmpowered";
import TestimonialSection from "./Testimonial/Testimonial";
import TeamMembers from "./TeamMembers/TeamMembers";
import React from 'react';

export default function Home() {

    // COMMENTED CODE IS A TRIAL PART OF IMPLEMENTING DYNAMIC CAROUSEL PLEASE DON'T UNCOMMENT IT.
    // const [photos, setPhotos] = useState(["pic1.jpg", "pic2.jpg", "pic3.jpg", "pic4.jpg"])
    // const [photoContainer, setPhotoContainer] = useState([]);

    // useEffect(() => {
    //     photos.map((photo, index) =>{
    //         let img = <img src={"carousel/" + photo} alt={photo} style={{width: "100%", heigth: '100%', objectFit:"contain"}} />;
    //         photoContainer.push(img);
    //     })
    // }, [])

    const [carouselData, setCarouselData] = useState([
        {
            image: "Life GPS-carousel.png",
            heading: 'Life GPS: Mapping Success & Satisfaction',
            text: "A First Year Induction Program"
        },
        {
            image: "pd.png",
            heading: "Personality Development Course",
            text: ""
        },
        {
            image: "pcl.png",
            heading: "Principle Centered Leadership Course",
            text: ""
        },
        {
            image: "7 habits.png",
            heading: "7 Habits of Highly Effective People Course",
            text: "",
        },
        {
            image: "dys.png",
            heading: "Discover Your Self Course",
            text: ""
        },
        {
            image: "quotient.png",
            heading: "The 4 Quotients of Life",
            text: "Balancing the Quotients: Navigating Life's Multidimensional Aspects"
        },
        {
            image: "career.png",
            heading : "Counselling & Career Guidance",
            text: "Making the right choice"
        },
        {
            image: "Talent.png",
            heading : "Discovering the Latent Talent",
            text: "Grooming the unexplored side of yourself"
        }
    ])

    // useEffect(() => {
    // })

    return (
        <>
            {/* <div id={s.videoContainer}>
                <video width="100%" height="100%" autoPlay muted loop>
                    <source src="bannerVideo.mp4" type="video/mp4" />
                </video>
            </div> */}
            <div id={s.HEROcarouselContainer}>
                <div id={s.HEROcarouselWrapper}>
                    <Carousel data={carouselData} />
                </div>
                {/* <div id={s.carouselOverlay} /> */}
            </div>
            <AboutShresthaClub />
            <WhatDoWeOffer />
            <Statistics />
            <EventCards />
            <GetEmpowered />
            <TestimonialSection />
            <TeamMembers />
            {/* <AboutUs /> */}
            <Footer />
        </>
    );
}


function Statistics(props){
    return(
        <div id={s.statisticsWrapper}>
            <div id={s.heading}>
                <h1>
                    Statistics
                </h1>
            </div>
            <div id={s.statisticsCardContainer}>
                <div className={s.card}>
                    <div className={s.scount}>
                        <h1>120+</h1>
                    </div>
                    <div className={s.stext}>
                        <p>Faculty of Engineers, Doctors, CA </p>
                    </div>
                </div>
                <div className={s.card}>
                    <div className={s.scount}>
                        <h1>30+</h1>
                    </div>
                    <div className={s.stext}>
                        <p>Branches across India and US</p>
                    </div>
                </div>
                <div className={s.card}>
                    <div className={s.scount}>
                        <h1>30+</h1>
                    </div>
                    <div className={s.stext}>
                        <p>Events and Webinars</p>
                    </div>
                </div>
                <div className={s.card}>
                    <div className={s.scount}>
                        <h1>3000+</h1>
                    </div>
                    <div className={s.stext}>
                        <p>Student Participants</p>
                    </div>
                </div>
                <span>
                    <img src="abstract/circle.svg" alt="Triangle" />
                </span>
            </div>
        </div>
    )
}


function Carousel(props) {
    // const [photos, updatePhotos] = useState(["pic1.jpg", "pic2.jpg", "pic3.jpg", "pic4.jpg"]);
    const [photoCount, updatePhotoCount] = useState(8);
    const [imageWidth, updateImageWidth] = useState(100 / photoCount);
    const [direction, setDirection] = useState(true); // True represents the forward direction and vice versa
    const [position, updatePosition] = useState(0);
    const intervalRef = useRef(null);

    const animateSlider = () => {
        if (position === -100) {
            setDirection(true); // Set direction back to true to go forward
        } else if (position === -1 * ((photoCount - 2) * 100)) {
            setDirection(false); // Update the direction using setDirection
        }
        console.log(position)
        if (direction) {
            updatePosition((prevPosition) => prevPosition - 100);
        } else {
            updatePosition((prevPosition) => prevPosition + 100);
        }
    }

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(animateSlider, 6000);
        return () => clearInterval(intervalRef.current);
    }, [direction, position, photoCount]);

    return (
        <div id={s.slider} style={{ position: "relative", left: `${position}%` }}>
            {
                props.data.map(cdata => {
                    return (
                        <div className={s.imageContainer} style={{ width: `${imageWidth}%`, background: "lightblue" }}>
                            {/* <div id={s.carouselText}>
                                <h1>{cdata.heading}</h1>
                                <p>{cdata.text}</p>
                                <a href="#" id={s.carouselCTA}>
                                    <div id={s.carouselCTAButton}>
                                        <p>Learn More</p>
                                    </div>
                                </a>
                            </div> */}
                            <img src={"Homepage/Carousel/" + cdata.image} alt={cdata.heading} />
                        </div>
                    )
                })
            }
        </div>
    )
}



function AboutUs(props) {
    return (
        <div id={s.aboutUsContianerWrapper}>
            <div id={s.aboutUsContainer}>
                <div id={s.AUCleftSection}>
                    <div id={s.AUCImageFloater}>
                        <div id={s.bigBubble}>
                            <h5>Trusted By</h5>
                            <h1>45k+</h1>
                            <div id={s.smallBubble}></div>
                        </div>
                    </div>
                    <div id={s.AUCLSImageContainer}>
                        <img src="aboutUSImage.jpg" alt="" />
                    </div>
                </div>

                <div id={s.AUCrightSection}>
                    <div id={s.AUmainTitle}>
                        <p>We are Experts Learning Institution</p>
                    </div>
                    <div id={s.experienceTitle}>
                        <p>20 Years of Experience</p>
                    </div>
                    <div id={s.briefIntro}>
                        <p>Do one absolutely bladdered say bugger all mate only a quid that chip shop amongst, cuppa excuse my French lemon squeezy bender zonked my lady gosh cup of tea, have it spiffing good time naff wind up codswallop crikey.</p>
                    </div>
                    <div id={s.phoneNumber}>
                        <p>Call Us <span style={{ color: "var(--primary-color)" }}>+456 968 562 75</span> </p>
                    </div>
                    <div id={s.CTAButtonContainer}>
                        <button>
                            About US
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}