import s from "./AboutShresthaClub.module.scss"
import React from 'react';

export default function AboutShresthaClub(props) {
    return (
        <div id={s.aboutShresthaClubWrapper}>
            <div id={s.aboutShresthaClubContainer}>
                <div id={s.ASCHeading}>
                    <h1 >
                        Welcome to Shrestha Club
                        <br />
                    </h1>
                    <span style={{fontSize:"1.5rem"}}><p>Where Purpose, Passion, and Performance Converge</p> </span>
                </div>
                <section className={s.sec}>
                    <div id={s.ASCcontent}>
                        <div id={s.ASCtext}>
                            <p>
                                Shrestha Club is a forum to help youths channelize their energy and potential to become a ‘Shrestha’ - ‘A Leader’ within the society.<br/><br/>
                                At Shrestha Club, we're more than a club; we're a thriving community united by purpose, passion, and performance. Character Centric Leadership fuels our connection to purpose, while our dynamic culture sets the stage for boundless passion. Competent Strategies ensure that our every move contributes to our legacy of exceptional performance.<br/><br/>
                            </p>
                        </div>
                    </div>
                    <div width="100%" id={s.ASCVideoContainer}>
                        <img src="Homepage/3P-3C.png" alt="Home Page" />
                        {/* <video width="100%" autoPlay muted loop>
                            <source src="bannerVideo.mp4" type="video/mp4" />
                        </video> */}
                    </div>
                </section>
                <section className={s.sec}>
                    <div width="100%" id={s.ASCVideoContainer}>
                        <img src="Homepage/About Shrestha Club.png" alt="Home Page" />
                    </div>
                    <div id={s.ASCcontent}>
                        <div id={s.ASCtext}>
                            <p>
                                Our objectives include creating principled leaders, holistic well-being, and discipline. Unlike others, we foster both skills and character. <br/><br/>
                                Experience holistic growth—physical, intellectual, emotional and spiritual—with us.<br/><br/>
                                Your impactful leadership journey starts here. Join us in this journey, where leadership is the spark, culture fuels the fire, and strategies propel us toward greatness. At Shrestha Club, excellence isn't a destination – it's woven into our DNA.
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}